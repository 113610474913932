import clsx from "clsx";

import styles from './styles.module.scss';

function ToTop() {
  return (
    <a href="#top" className={clsx('back-to-top', styles.Root)}>
      <i className="lni lni-chevron-up"> </i>
    </a>
  );
}

export default ToTop;