import { Route, Routes } from 'react-router-dom';

// Pages Import
import LandingPage from '@page/Landing';
import AboutPage from '@page/About';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<AboutPage />} />
    </Routes>
  );
}

export default Router;