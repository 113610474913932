import styles from './styles.module.scss';

function Footer() {
  return (
    <footer class="ud-footer wow fadeInUp" data-wow-delay=".15s">
      <div class="shape shape-1">
        <img src="/assets/vendors/images/footer/shape-1.svg" alt="shape" />
      </div>
      <div class="shape shape-2">
        <img src="/assets/vendors/images/footer/shape-2.svg" alt="shape" />
      </div>
      <div class="shape shape-3">
        <img src="/assets/vendors/images/footer/shape-3.svg" alt="shape" />
      </div>

      <div class="ud-footer-widgets">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="ud-widget">
                <a href="/" class="ud-footer-logo">
                  <img src="/assets/svg/logo-alt.svg" alt="" className={styles.LogoImage} />
                </a>
                <p class="ud-widget-desc">
                  <small><strong>Vibetree</strong> merupakan sebuah studio konveksi kaos sablon satuan maupun paket yang membantu
                  designer untuk menjual hasil design mereka.</small>
                </p>
                <ul class="ud-widget-socials">
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i class="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i class="lni lni-twitter-filled"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MusharofChy">
                      <i class="lni lni-instagram-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Vibetree</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a href="javascript:void(0)">Fitur</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Tentang</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Bantuan</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Dokumentasi</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a href="javascript:void(0)">Designer</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Pembeli</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Katalog Design</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a
                      href="https://lineicons.com/"
                      rel="noreferrer noopener"
                      target="_blank"
                      >Original
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://lineicons.com/"
                      rel="noreferrer noopener"
                      target="_blank"
                      >Hot Deals
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ecommercehtml.com/"
                      rel="noreferrer noopener"
                      target="_blank"
                      >Unisex</a>
                  </li>
                  <li>
                    <a
                      href="https://ayroui.com/"
                      rel="noreferrer noopener"
                      target="_blank"
                      >Pria</a>
                  </li>
                  <li>
                    <a
                      href="https://graygrids.com/"
                      rel="noreferrer noopener"
                      target="_blank"
                      >Wanita</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ud-footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <ul class="ud-footer-bottom-left">
                <li>
                  <a href="javascript:void(0)">Kebijakan Privasi</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Ketentuan &amp; Persyaratan</a>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <p class="ud-footer-bottom-right">
                <small>&copy; 2022, Vibetree Studio. Allright Reserved.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;