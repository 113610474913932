import clsx from "clsx";

import styles from './styles.module.scss';

function HeroBanner() {
  return (
    <section className="ud-hero" id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
              <h1 className="ud-hero-title">
                Jadikan Design Kamu Sebagai Ladang Bisnis Sekarang Juga!
              </h1>
              <p className="ud-hero-desc">
                <strong>Vibetree</strong> adalah platform studio sablon daring yang memudahkan kamu menjual design-mu menjadi sebuah bisnis.
              </p>
              <ul className="ud-hero-buttons">
                <li>
                  <a href="/katalog" rel="nofollow noreferrer noopener" target="_blank" className="ud-main-btn ud-white-btn">
                    Buat Kaos Sekarang
                  </a>
                </li>
                <li>
                  <a href="/fitur" rel="nofollow noreferrer noopener" target="_blank" className={clsx('ud-main-btn ud-link-btn', styles.Button)}>
                    Pelajari Lebih Lanjut <i className="lni lni-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="ud-hero-image wow fadeInUp" data-wow-delay=".25s">
              <img src="assets/vendors/images/hero/hero-image.svg" alt="" />
              <img
                src="assets/vendors/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-1"
              />
              <img
                src="assets/vendors/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-2"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroBanner;