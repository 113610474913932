import PropTypes from 'prop-types';
import clsx from "clsx";

import styles from './styles.module.scss';

function Feature({
  type = '',
  subTitle = 'Sub Title',
  title = 'Title',
  description = 'Description',
  items = [],
  notices = []
}) {
  return (
    <section id="features" className={clsx('ud-features', styles.Root)}>
        <div className="container">
          <div className="row">
            <div className="">
              <div className={clsx('ud-section-title', styles.Title)}>
                <span>{subTitle}</span>
                <h2>{title}</h2>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
          <div className="row">
            {items.map((item, k) => (
              <div key={`item-${type}-${k}`} className="col-xl-3 col-lg-3 col-sm-6">
                <div className="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
                  <div className={clsx('ud-feature-icon', styles.Icon)}>
                    <i className={`lni lni-${item.icon}`}></i>
                  </div>
                  <div className="ud-feature-content">
                    <h3 className="ud-feature-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p className="ud-feature-desc" dangerouslySetInnerHTML={{ __html: item.description }} />
                    {item.url && (
                      <a href={item.url} className="ud-feature-link">
                        Pelajari Lebih Lanjut
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {notices.length > 0 && (
            <ul>
              {notices.map((notice, k) => (
                <li key={`notice-${type}-${k}`}><em><small dangerouslySetInnerHTML={{ __html: `[${k + 1}] ${notice}` }} /></em></li>
              ))}
            </ul>
          )}
        </div>
      </section>
  );
}

Feature.propTypes = {
  type: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
  })),
  notices: PropTypes.arrayOf(PropTypes.string)
};

export default Feature;