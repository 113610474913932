import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './Router';
import bootstrap from './bootstrap';

import * as Context from '@logic/context';

import './themes/v1/main.scss';

function App() {
  useEffect(() => {
    // Bootstrap
    bootstrap();
  }, []);

  return (
    <BrowserRouter>
      <Context.Menu.Provider value={Context.MenuValues}>
        <Router />
      </Context.Menu.Provider>
    </BrowserRouter>
  );
}

export default App;