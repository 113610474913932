import clsx from 'clsx';

import * as Menu from '@components/elements/Menu';

import styles from './styles.module.scss';

function Navbar() {
  return (
    <header className="ud-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className={clsx('navbar-brand', styles.Brand)} href="/">
                <img src="/assets/svg/logo-alt.svg" alt="" />
              </a>
              <button className="navbar-toggler">
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
              </button>

              <div className="navbar-collapse">
                <Menu.Navbar />
              </div>

              <div className="navbar-btn d-none d-sm-inline-block">
                <a href="/member/login" className="ud-main-btn ud-login-btn">
                  Masuk
                </a>
                <a className="ud-main-btn ud-white-btn" href="/member/daftar">
                  Daftar Baru
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;