import Footer from "@components/modules/Footer";
import Navbar from "@components/modules/Navbar";
import ToTop from "@components/elements/ToTop";
import * as Banner from '@components/modules/Banner';
import * as Section from '@components/modules/Section';

import featureData from './data/feature.json';

function LandingPage() {
  return (
    <>
      <Navbar />

      <Banner.Hero />
      <Section.Feature
        type="designer"
        title={featureData.designer.title}
        subTitle={featureData.designer.subTitle}
        description={featureData.designer.description}
        items={featureData.designer.items}
        notices={featureData.designer.notices} />

      <Section.Feature
        type="consumer"
        title={featureData.consumer.title}
        subTitle={featureData.consumer.subTitle}
        description={featureData.consumer.description}
        items={featureData.consumer.items}
        notices={featureData.consumer.notices} />

      <ToTop />

      <Footer />
    </>
  );
}

export default LandingPage;