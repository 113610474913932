import { useContext } from 'react';

import * as Context from '@logic/context';

function NavbarMenu() {
  const menu = useContext(Context.Menu);
  return (
    <ul id="nav" className="navbar-nav mx-auto">
      {menu.map((m, k) => (
        <li className="nav-item" key={`menu-${k}`}>
          <a href={m.url}>{m.label}</a>
        </li>
      ))}
    </ul>
  );
}

export default NavbarMenu;